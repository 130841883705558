<template>
  <div class="recommend-goods" v-if="list">
    <div class="box">
      <div class="title fx-row fx-v-center b-b">
        <div class="fx-1">Produk Unggulan</div>
      </div>

      <div class="goods-list fx-row fx-wrap">
        <div>
          <div v-for="(item, index) of list.left" :key="index">
            <good-item :info="item" @fresh="fresh"></good-item>
          </div>
        </div>
        <div>
          <good-item v-for="(item, index) of list.right"
                     :key="index" :info="item"></good-item>
        </div>
      </div>
    </div>

    <div class="end">- Tidak ada produk lagi -</div>
  </div>
</template>

<script>
  import {filter} from 'ramda'
  //component
  import GoodItem from './goodItem'
  //api
  import service from '../service'

  export default {
    data() {
      return {
        list: false
      }
    },
    components: {
      GoodItem
    },
    methods: {
      init() {
        this.getRecommendGoods()
      },
      fresh() {
        this.init()
      },
      getRecommendGoods() {
        service.getRecommendGoods().then((data) => {
          if (data && data.activities && data.activities.length > 0) {
            let result = {left: [], right: []}
            let activities = data.activities
            let daily = (filter((item) => {
              return item.category == 4
            })(activities))[0]
            let others = filter((item) => {
              return item.category != 3 && item.category != 4
            })(activities)

            //插入每日推荐
            if (daily) {
              others.unshift(daily)
            }

            others.forEach((item, index) => {
              let ood = (index % 2) == 0
              //是否偶数
              if (!ood) {
                result.right.push(item)
              } else {
                result.left.push(item)
              }
            })

            this.list = result
          }
        })
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .end {
    text-align: center;
    margin-top: pxTo(12);

    @extend %ff-rr;
    @include fs-l-w-c(12, 14, 400, $c10);
  }

  .goods-list {
    padding: pxTo(12) pxTo(16) pxTo(1) pxTo(16);

    > div {
      width: pxTo(165);

      &:nth-last-of-type(1) {
        margin-left: pxTo(11);
      }
    }
  }
</style>