<template>
  <div class="groups-intro box">
    <div class="title b-b fx-row fx-center">
      <div class="fx-1">CARA MENDAPATKAN KUPON DISKON</div>
      <div class="more" @click="gotoIntro">Lihat Lebih></div>
    </div>

    <div class="content">
      <template v-if="num>0">
        - <span>1 Grup = {{num}} orang.</span><br/>
        - Kupon Didapatkan Setelah Grup Penuh
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['num'],
    methods: {
      gotoIntro() {
        this.gotoPage('intro')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .groups-intro {
    .content {
      height: pxTo(68);
      padding: pxTo(21) 0 0 pxTo(88);

      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);
      @include bg($img-base, 'img_group_shopping.svg');
      background-size: pxTo(40) pxTo(40);
      background-position: pxTo(20) center;

      > span {
        color: $c1;
        font-weight: 500 !important;

        @extend %ff-rr;
      }
    }
  }
</style>